import React from 'react'
import GlobalStyles from './../styles/GlobalStyles'
import ColourMode from './ColourMode'
import './../styles/typography.scss'
import tw from 'twin.macro'

const Layout = ({ children, ...rest }) => (
  <div {...rest} tw='dark:(background-color[#272a2f]) min-height[100vh]'>
    <GlobalStyles />
    <ColourMode/>
    {children}
  </div>
)

export default Layout
