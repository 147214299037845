import React from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'

const Logo = () => (
  <Link to='/'>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={36}
      viewBox="0 0 18 36"
      fill='#fff'
      tw='fixed top-2 left-1 md:(top-10 left-6) z-50 mix-blend-mode[difference] opacity-70 transition-opacity duration-150 ease-in-out hover:(opacity-100)'
    >
      <path d="M8.683 18c1.706 0 3.226.384 4.56 1.152a8.317 8.317 0 013.136 3.152c.757 1.333 1.136 2.864 1.136 4.592 0 1.792-.374 3.355-1.12 4.688a7.977 7.977 0 01-3.12 3.12c-1.334.747-2.875 1.12-4.624 1.12-2.155 0-3.958-.533-5.408-1.6C1.877 33.22.918 31.819.366 30.022l-.1-.342H5.74c.234.619.608 1.088 1.12 1.408.512.32 1.152.48 1.92.48.597 0 1.12-.133 1.568-.4a2.685 2.685 0 001.04-1.136c.21-.42.338-.904.384-1.45l.016-.278H.17c-.256-1.408-.224-2.736.096-3.984a8.424 8.424 0 011.648-3.296A8.078 8.078 0 014.81 18.8c1.152-.533 2.442-.8 3.872-.8zm.128 4.032c-.832 0-1.499.261-2 .784-.456.475-.714 1.087-.773 1.835l-.011.229h5.728c-.064-.576-.224-1.077-.48-1.504a2.688 2.688 0 00-1.024-.992 2.938 2.938 0 00-1.44-.352zM8.683 12c1.706 0 3.226.384 4.56 1.152a8.317 8.317 0 013.136 3.152c.223.393.414.804.571 1.232h-5.586l-.09-.16a2.688 2.688 0 00-1.023-.992 2.938 2.938 0 00-1.44-.352c-.832 0-1.499.261-2 .784a2.648 2.648 0 00-.493.72H.505a8.28 8.28 0 011.41-2.512A8.078 8.078 0 014.81 12.8c1.152-.533 2.442-.8 3.872-.8zm0-6c1.706 0 3.226.384 4.56 1.152a8.317 8.317 0 013.136 3.152c.223.393.414.804.571 1.232h-5.586l-.09-.16a2.688 2.688 0 00-1.023-.992 2.938 2.938 0 00-1.44-.352c-.832 0-1.499.261-2 .784a2.648 2.648 0 00-.493.72H.505a8.28 8.28 0 011.41-2.512A8.078 8.078 0 014.81 6.8C5.963 6.267 7.253 6 8.683 6zm0-6c1.706 0 3.226.384 4.56 1.152a8.317 8.317 0 013.136 3.152c.223.393.414.804.571 1.232h-5.586l-.09-.16a2.688 2.688 0 00-1.023-.992 2.938 2.938 0 00-1.44-.352c-.832 0-1.499.261-2 .784a2.648 2.648 0 00-.493.72H.505a8.28 8.28 0 011.41-2.512A8.078 8.078 0 014.81.8C5.963.267 7.253 0 8.683 0z" />
    </svg>
  </Link>
)

export default Logo
