import React from 'react'
import tw from 'twin.macro'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const ArticleImage = ({ alt, src }) => (
  <GatsbyImage
    alt={alt}
    image={getImage(src)}
    tw='my-6 md:(my-8) lg:(my-12)'
  />
)

export default ArticleImage
