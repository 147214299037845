import React from "react"
import useTheme from './../hooks/useTheme'
import tw from 'twin.macro'

const ColourMode = props => {
  const [ colorTheme, setTheme ] = useTheme()
  return (
    <button
      onClick={e => { setTheme(colorTheme) }}
      tw="absolute z-50 top-4 right-4 appearance-none focus:(outline-none) active:(outline-none)">
      <div
        css={[
          tw`rounded-full w-8 transition`,
          (colorTheme === 'dark') ? tw`border border-gray-200 bg-white ` : tw`border border-gray-900 bg-blue-600`,
        ]}>
        <div
          css={[
            tw`w-4 h-4 rounded-full transition`,
            (colorTheme === 'dark') ? tw`bg-gray-500` : tw`bg-gray-400 transform translate-x-4`,
          ]} />
      </div>
    </button>
  )
}

export default ColourMode
